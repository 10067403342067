/* ShareButton.css */
/* Styling for aligning title and description on the right side of the image */
/* You can adjust the styles as per your preference */

.og-image {
    width: 250px; /* Set image width */
    height: 250px; /* Set image height */
    position: relative;
  }
  
  .og-description {
    position: absolute;
    bottom: 10px; /* Adjust as needed */
    right: 10px; /* Adjust as needed */
    color: white; /* Color of description text */
    background-color: rgba(0, 0, 0, 0.5); /* Background color for better readability */
    padding: 5px 10px; /* Padding for description text */
  }
  
  .og-title {
    position: absolute;
    top: 10px; /* Adjust as needed */
    right: 10px; /* Adjust as needed */
    color: white; /* Color of title text */
    background-color: rgba(0, 0, 0, 0.5); /* Background color for better readability */
    padding: 5px 10px; /* Padding for title text */
  }
  